import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import {
  AlertConfig,
  AlertController as Alert,
  AlertTypes,
  LoadingConfig,
  LoadingController as Loading,
  ToastController as Toast,
  ToastTypes,
} from '@cds.js/core';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';

export interface AlertSettings {
  text: string;
  title?: string;
  htmlFlag?: boolean;
  type?: AlertTypes;
  confirmButtonText?: string;
  interpolateParamsTranslate?: object;
}

export interface ToastSettings {
  type: ToastTypes;
  text: string;
  title?: string;
  interpolateParamsTranslate?: object;
}

@Injectable({
  providedIn: 'root',
})
export class OverlayFacade {
  private translateService: TranslateService = inject(TranslateService);
  private document: Document = inject(DOCUMENT);

  private defaultOptionsAlert: AlertConfig = {
    type: 'confirm',
    preventClose: true,
    reverseButtons: false,
  };

  private readonly defaultNotificationTitles: {
    [key in AlertTypes | ToastTypes]: string;
  } = {
    confirm: 'ALERTS.TITLES.CONFIRM',
    error: 'ALERTS.TITLES.ERROR',
    info: 'ALERTS.TITLES.INFO',
    success: 'ALERTS.TITLES.SUCCESS',
    warning: 'ALERTS.TITLES.WARNING',
    primary: 'ALERTS.TITLES.INFO',
  };

  private readonly defaultOptionsLoader: LoadingConfig = {
    type: 'cargamos',
    message: 'COMMON.WAIT_FOR_A_MOMENT',
  };

  private readonly alertCtrl;
  private readonly toastCtrl;
  private readonly loadingCtrl;

  constructor() {
    this.alertCtrl = this.getAlertInstance();
    this.toastCtrl = this.getToastInstance();
    this.loadingCtrl = this.getLoadingInstance();
  }

  public showModalAlert(props: AlertSettings): Observable<{ isConfirmed: boolean }> {
    const options: AlertConfig = {
      ...this.defaultOptionsAlert,
      ...props,
      cancelButtonText: this.translateService.instant('COMMON.CANCEL'),
      confirmButtonText: this.translateService.instant(props.confirmButtonText || 'COMMON.ACCEPT'),
      title: this.getTitle('confirm', props.title),
      withButtonClose: true,
    };
    if (props.text) {
      options.text = this.translateService.instant(props.text, props.interpolateParamsTranslate);
    }

    if (props.htmlFlag) {
      options.html = options.text;
      delete options.text;
    }

    return from(this.alertCtrl.showAlertConfirm(options));
  }

  public showToast(props: ToastSettings): void {
    if (props.text) {
      props.text = this.translateService.instant(props.text, props.interpolateParamsTranslate);
    }
    this.toastCtrl.show({
      ...props,
      title: this.getTitle(props.type, props.title),
      progress: false,
      timer: 4000,
      position: 'bottom-start',
    });
  }

  public showNotification(options: {
    type: AlertTypes;
    title?: string;
    text?: string;
    html?: string;
  }): void {
    const alertOptions: AlertConfig = {
      ...options,
      confirmButtonText: this.translateService.instant('COMMON.ACCEPT'),
      title: this.getTitle(options.type, options.title),
      withButtonClose: true,
    };

    if (options.text) {
      alertOptions.text = this.translateService.instant(options.text);
    }

    if (options.title) {
      alertOptions.title = this.translateService.instant(options.title);
    }

    this.alertCtrl.showAlert(alertOptions);
  }

  public showLoading(props?: LoadingConfig): HTMLCdsLoadingElement {
    const message = props?.message || this.defaultOptionsLoader.message || '';

    return this.loadingCtrl.show({
      ...this.defaultOptionsLoader,
      message: this.translateService.instant(message),
    });
  }

  public closeAllLoading(): void {
    Array.from(this.document.querySelectorAll('cds-loading')).forEach(
      async loading => await loading.close()
    );
  }

  private getAlertInstance(): Alert {
    if (!this.alertCtrl) {
      return new Alert();
    }

    return this.alertCtrl;
  }

  private getToastInstance(): Toast {
    if (!this.toastCtrl) {
      return new Toast();
    }

    return this.toastCtrl;
  }

  private getLoadingInstance(): Loading {
    if (!this.loadingCtrl) {
      return new Loading();
    }

    return this.loadingCtrl;
  }

  private getTitle(type: AlertTypes | ToastTypes, title?: string): string {
    if (title) {
      return this.translateService.instant(title);
    }

    return this.translateService.instant(this.defaultNotificationTitles[type]);
  }
}
